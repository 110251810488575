// Component states
import states from './settings-qrcode-modifier.state.js'
import workspace from '@/components/parts/workspace/workspace.state'

// Services
import { landingPageMode } from '@/services/states/states.service'
import { eventEmit } from '@/services/utils/utils.service'

// Components
import ImagePathModifier from '../image-path-modifier/Image-path-modifier.vue'
import MdiInformationOutline from 'vue-material-design-icons/InformationOutline.vue'
import AlignHorizontalContentModifier from '../align-horizontal-content-modifier/Align-horizontal-content-modifier.vue'

// Library
import {
  cloneDeep as _cloneDeep
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-mj-image'

// Properties
const props = {
  mjml: Object
}

// Vue@data
const data = function () {
  return {
    states: _cloneDeep(states),
    workspace
  }
}
// Vue@data

// Components
const components = {
  ImagePathModifier,
  MdiInformationOutline,
  AlignHorizontalContentModifier
}

// Methods
const methods = {
  /**
   * Generate image with correct size
   * @param {String} src (url)
   */
  _setImageProperties (src) {
    const img = new Image()
    img.onload = () => {
      const bodySize = parseInt(workspace.template.children[0].attributes.width)
      this.$set(this.states, 'maxWidth', img.width > bodySize ? bodySize : img.width)
      this.$set(this.states, 'imageWidth', img.width)
      if (this.states.maxWidth < this.states.tempWidth) {
        this.$set(this.states, 'tempWidth', this.states.maxWidth)
      }
    }
    img.onerror = () => {
      const bodySize = parseInt(workspace.template.children[0].attributes.width)
      this.$set(this.states, 'imageWidth', null)
      this.$set(this.states, 'maxWidth', bodySize)
      this.$set(this.states, 'tempWidth', this.states.maxWidth)
    }
    img.src = src
  },
  /**
   * Function to set url to current componenent from event
   * Sets the src attribute of the image with the new path.
   * @param  {data} object   (object sent from event)
   */
  setFileUrlFromEvent: function (event) {
    if (typeof event.data !== 'object') { return }
    const call = event.data.call
    const value = event.data.value
    if (call === 'file-manager:file' && value.fallback === 'settings') {
      const qrcode = value.file
      if (qrcode.size) this.width = qrcode.size
      if (qrcode.name) this.mjml.attributes.title = qrcode.name
      if (qrcode.type_id) this.mjml.attributes.rel = qrcode.type_id === 1 ? 'text' : 'url'
      if (qrcode.redir_content) this.mjml.attributes.alt = qrcode.redir_content
      if (qrcode.img_url) this.mjml.attributes.src = qrcode.img_url
    }
  },
  /**
   * Function to handle opening of qrcode manager
   */
  openQrcodeManager: function () {
    eventEmit('open-qrcode-manager', { fallback: 'settings' })
  }
}

// Computed Methods
const computed = {

  /**
   * Check DND mode
   */
  isLandingMode () {
    return landingPageMode().get()
  },

  manualWidth: {
    // getter
    get () {
      return this.states.widthManual
    },
    // setter
    set (newValue) {
      this.states.widthManual = newValue
      this.mjml.attributes.width = newValue ? `${parseInt(this.states.tempWidth)}px` : ''
    }
  },

  TitleText: {
    // getter
    get () {
      return this.mjml.attributes.title.replace(/&quot;/g, '"')
    },
    // setter
    set (newValue) {
      const val = newValue.replace(/"/g, '&quot;') // Fix MJML issue with with `"` in html attr
      this.$set(this.mjml.attributes, 'title', val)
    }
  },

  alternateText: {
    // getter
    get () {
      return this.mjml.attributes.alt.replace(/&quot;/g, '"')
    },
    // setter
    set (newValue) {
      const val = newValue.replace(/"/g, '&quot;') // Fix MJML issue with with `"` in html attr
      this.$set(this.mjml.attributes, 'alt', val)
    }
  },

  width: {
    // getter
    get () {
      const bodySize = parseInt(workspace.template.children[0].attributes.width)
      if (this.mjml.attributes.width !== '') {
        this.states.tempWidth = this.mjml.attributes.width
      }

      const width = this.states.widthManual
        ? this.mjml.attributes.width || bodySize
        : this.states.tempWidth

      return parseInt(width, 10)
    },
    // setter
    set (newValue) {
      if (!this.manualWidth) return
      this.mjml.attributes.width = `${newValue}px`
    }
  }
}

const watch = {
  'mjml.attributes.src': function (newSrc) {
    this._setImageProperties(newSrc)
  }
}

/**
 * Init widthAuto state
 */
function initState () {
  if (!this.mjml.attributes.width) {
    this.states.widthManual = true
  }

  this._setImageProperties(this.mjml.attributes.src)
  window.addEventListener('message', this.setFileUrlFromEvent)
}

// Func@removeWindowListener on destroy
/**
 * Remove window resize event listener
 */
function destroyed () {
  window.removeEventListener('message', this.setFileUrlFromEvent)
}
// Func@removeWindowListener

// Vue component syntax
export default {
  name,
  data,
  props,
  watch,
  methods,
  computed,
  components,
  created: initState,
  destroyed
}
